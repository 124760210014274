<template>
    <MyModal :on_off="modal">ma'lumotlar yuklanmoqda...</MyModal>
    <div class="qonun ms-2 me-2 mt-5">
            <div class="row g-10">
            <div class=" col-md-3">
            <div class="bg-light">
                <a href="/" class="link-dark text-decoration-none">
                <span class="fs-4 ms-2">Bo'limlar</span>
                </a>
                <hr>
                
                <ul class="nav nav-pills flex-column mb-auto">
                <li v-for="item in tags" :key="item">
                    <a href="#" @click="setactive(item)" :class="[item.id==active_tag.id ?  'nav-link active': item.id!=active_tag.id, 'nav-link link-dark']">
                        {{item.title}}
                    </a>
                </li>
                </ul>
                <hr>
            </div>
            </div>
            <div class="col-md-9 px-5">
                <div id="hukumat" class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative"
                v-for="hujjat in hujjatlar"
                :key="hujjat.id"
                >
                    <div class="col p-4 d-flex flex-column position-static">
                    <strong class="d-inline-block mb-2 text-primary">{{hujjat.teg_name}}</strong>
                    <h3 class="mb-0">{{hujjat.name}}</h3>
                    <div class="mb-1 text-muted">{{hujjat.date.substring(0, 10)}}</div>
                    <p class="card-text mb-auto">{{hujjat.short_description}}</p>
                    <a :href="'/hujjat/'+hujjat.id" class="stretched-link">Davomini o'qish</a>
                    </div>
                </div>          
            </div>
        
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import MyModal from "@/components/MyModal.vue";
export default {
    components:{
        MyModal
    },
    data(){
        return{
            slug:this.$route.params.slug,
            hujjatlar:null,
            tags:null,
            active_tag:{
                "id":1,
                slug:null
            },
            modal:true
        }
    },
    mounted(){
        // ma'lumotlar yuklanmoqda...
        this.modal=true
        setTimeout(()=>{
            axios
                .get(`courses/get_hujjat_tags/`)
                .then(response=>{
                    // TODO
                    this.tags=response.data
                    this.active_tag=response.data[0]
                axios
                    .get(`courses/get_hujjat_by_tags/${this.active_tag.slug}`)
                    .then(response=>{
                        this.hujjatlar=response.data
                        this.modal=false
                    })
                })
        },500)
    },
    methods:{
        setactive(qiymat){
            this.active_tag=qiymat
            axios
                .get(`courses/get_hujjat_by_tags/${this.active_tag.slug}`)
                .then(response=>{
                    this.hujjatlar=response.data
                })
        }
    }
}
</script>