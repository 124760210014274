<template>
    <MyModal :on_off="modal">ma'lumotlar yuklanmoqda...</MyModal>
    <div class="tinglovchilar">
        <div class="ms-2 me-2 mt-3 mb-4">
            <h2 class="text-center">
                Tinglovchilar soni - {{computedList.length}}
            </h2>
            <div class="table" v-if="computedList.length<6" 
            >
                <table class="table">
                <thead>
                    <tr>
                        <th scope="col">№</th>
                        <th scope="col">Ism</th>
                        <th scope="col">Familiya</th>
                        <th scope="col">Sharif</th>
                        <th scope="col">
                            <div class="dropdown">
                                <button
                                    style="padding:0"
                                    class="btn dropdown-toggle"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    {{faoliyat.key}}
                                </button>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="#" @click="faoliyat={key:'Barchasi',value:'Faoliyat'}">Barchasi</a></li>
                                    <li><a class="dropdown-item" href="#" @click="faoliyat={key:'Band bo\'lmagan fuqaro',value:'fuqaro'}">Band bo'lmagan fuqaro</a></li>
                                    <li><a class="dropdown-item" href="#" @click="faoliyat={key:'Tashkilot hodimi',value:'hodim'}">Tashkilot hodimi</a></li>
                                </ul>
                            </div>
                        </th>
                        <th scope="col">
                            <div class="dropdown">
                                <button
                                    style="padding:0"
                                    class="btn dropdown-toggle"
                                    type="button" 
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    {{viloyat}}
                                </button>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="#" @click="viloyat='Hudud'">Barchasi</a></li>
                                    <li><a class="dropdown-item" href="#" @click="viloyat='Toshkent shahri'">Toshkent shahri</a></li>
                                    <li><a class="dropdown-item" href="#" @click="viloyat='Toshkent viloyati'">Toshkent viloyati</a></li>
                                    <li><a class="dropdown-item" href="#" @click="viloyat='Andijon viloyati'">Andijon viloyati</a></li>
                                    <li><a class="dropdown-item" href="#" @click="viloyat='Buxoro viloyati'">Buxoro viloyati</a></li>
                                    <li><a class="dropdown-item" href="#" @click="viloyat='Farg\'ona viloyati'">Farg'ona viloyati</a></li>
                                    <li><a class="dropdown-item" href="#" @click="viloyat='Jizzax viloyati'">Jizzax viloyati</a></li>
                                    <li><a class="dropdown-item" href="#" @click="viloyat='Xorazm viloyati'">Xorazm viloyati</a></li>
                                    <li><a class="dropdown-item" href="#" @click="viloyat='Namangan viloyati'">Namangan viloyati</a></li>
                                    <li><a class="dropdown-item" href="#" @click="viloyat='Navoiy viloyati'">Navoiy viloyati</a></li>
                                    <li><a class="dropdown-item" href="#" @click="viloyat='Qashqadaryo viloyati'">Qashqadaryo viloyati</a></li>
                                    <li><a class="dropdown-item" href="#" @click="viloyat='Qoraqalpog\'iston Respublikasi'">Qoraqalpog'iston Respublikasi</a></li>
                                    <li><a class="dropdown-item" href="#" @click="viloyat='Samarqand viloyati'">Samarqand viloyati</a></li>
                                    <li><a class="dropdown-item" href="#" @click="viloyat='Sirdaryo viloyati'">Sirdaryo viloyati</a></li>
                                    <li><a class="dropdown-item" href="#" @click="viloyat='Surxondaryo viloyati'">Surxondaryo viloyati</a></li>
                                </ul>
                            </div>
                        </th>
                        <th scope="col">Tashkilot nomi</th>
                        <th scope="col">sertifikat</th>
                        <th scope="col">id</th>
                    </tr>
                </thead>
                <!-- style="height:226px" -->
                <tbody>
                    <tr v-if="!computedList.length" style="height:226px">
                        <th scope="row" colspan="9">
                            <h1 class="text-center" style="margin-top:50px">Ma'lumot <br> topilmadi!</h1>
                        </th>
                    </tr>
                    <tr v-for="(item,index) in computedList" :key="index"  v-else>
                        <th scope="row">{{index+1}}</th>
                        <td>{{item.ism}}</td>
                        <td>{{item.familiya}}</td>
                        <td>{{item.sharif}}</td>
                        <td>{{faoliyat_fun(item.faoliyat)}}</td>
                        <td>{{item.viloyat}}</td>
                        <td>{{item.tash_nomi}}</td>
                        <td>{{sertifikat(item.sertifikat_bool)}}</td>
                        <td>{{item.unique_key}}</td>
                    </tr>
                </tbody>
                </table>
            </div>
            <div class="table-responsive-lg" v-else>
                <table class="table">
                <thead>
                    <tr>
                        <th scope="col">№</th>
                        <th scope="col">Ism</th>
                        <th scope="col">Familiya</th>
                        <th scope="col">Sharif</th>
                        <th scope="col">
                            <div class="dropdown">
                                <button
                                    style="padding:0"
                                    class="btn dropdown-toggle"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    {{faoliyat.key}}
                                </button>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="#" @click="faoliyat={key:'Barchasi',value:'Faoliyat'}">Barchasi</a></li>
                                    <li><a class="dropdown-item" href="#" @click="faoliyat={key:'Band bo\'lmagan fuqaro',value:'fuqaro'}">Band bo'lmagan fuqaro</a></li>
                                    <li><a class="dropdown-item" href="#" @click="faoliyat={key:'Tashkilot hodimi',value:'hodim'}">Tashkilot hodimi</a></li>
                                </ul>
                            </div>
                        </th>
                        <th scope="col">
                            <div class="dropdown">
                                <button
                                    style="padding:0"
                                    class="btn dropdown-toggle"
                                    type="button" 
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    {{viloyat}}
                                </button>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="#" @click="viloyat='Hudud'">Barchasi</a></li>
                                    <li><a class="dropdown-item" href="#" @click="viloyat='Toshkent shahri'">Toshkent shahri</a></li>
                                    <li><a class="dropdown-item" href="#" @click="viloyat='Toshkent viloyati'">Toshkent viloyati</a></li>
                                    <li><a class="dropdown-item" href="#" @click="viloyat='Andijon viloyati'">Andijon viloyati</a></li>
                                    <li><a class="dropdown-item" href="#" @click="viloyat='Buxoro viloyati'">Buxoro viloyati</a></li>
                                    <li><a class="dropdown-item" href="#" @click="viloyat='Farg\'ona viloyati'">Farg'ona viloyati</a></li>
                                    <li><a class="dropdown-item" href="#" @click="viloyat='Jizzax viloyati'">Jizzax viloyati</a></li>
                                    <li><a class="dropdown-item" href="#" @click="viloyat='Xorazm viloyati'">Xorazm viloyati</a></li>
                                    <li><a class="dropdown-item" href="#" @click="viloyat='Namangan viloyati'">Namangan viloyati</a></li>
                                    <li><a class="dropdown-item" href="#" @click="viloyat='Navoiy viloyati'">Navoiy viloyati</a></li>
                                    <li><a class="dropdown-item" href="#" @click="viloyat='Qashqadaryo viloyati'">Qashqadaryo viloyati</a></li>
                                    <li><a class="dropdown-item" href="#" @click="viloyat='Qoraqalpog\'iston Respublikasi'">Qoraqalpog'iston Respublikasi</a></li>
                                    <li><a class="dropdown-item" href="#" @click="viloyat='Samarqand viloyati'">Samarqand viloyati</a></li>
                                    <li><a class="dropdown-item" href="#" @click="viloyat='Sirdaryo viloyati'">Sirdaryo viloyati</a></li>
                                    <li><a class="dropdown-item" href="#" @click="viloyat='Surxondaryo viloyati'">Surxondaryo viloyati</a></li>
                                </ul>
                            </div>
                        </th>
                        <th scope="col">Tashkilot nomi</th>
                        <th scope="col">sertifikat</th>
                        <th scope="col">id</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in computedList" :key="index">
                        <th scope="row">{{index+1}}</th>
                        <td>{{item.ism}}</td>
                        <td>{{item.familiya}}</td>
                        <td>{{item.sharif}}</td>
                        <td>{{faoliyat_fun(item.faoliyat)}}</td>
                        <td>{{item.viloyat}}</td>
                        <td>{{item.tash_nomi}}</td>
                        <td>{{sertifikat(item.sertifikat_bool)}}</td>
                        <td>{{item.unique_key}}</td>
                    </tr>
    
                </tbody>
                </table>
            </div>

        </div>
    </div>
</template>

<script>
import axios from 'axios'
import MyModal from "@/components/MyModal.vue";
export default {
    components:{
        MyModal
    },
 data(){
    return{
        faoliyat:{key:'Barchasi',value:'Faoliyat'},
        viloyat:'Hudud',
        data:[
        ],
        result:[],
        modal:true
    }
 },
 mounted(){
    this.modal=true
    setTimeout(()=>{
        axios
        .get(`account/get_users/`)
        .then(response=>{
            this.data=response.data
            this.modal=false
        })
    },500)
 },
   computed: {
    computedList() {
        if(this.faoliyat.value!=="Faoliyat"){
            if(this.viloyat!=="Hudud"){
                this.result=this.data.filter((item) => item.viloyat.toLowerCase().includes(this.viloyat.toLowerCase()))
                return this.result.filter((item) => item.faoliyat.toLowerCase().includes(this.faoliyat.value.toLowerCase()))

            }else{
                return this.data.filter((item) => item.faoliyat.toLowerCase().includes(this.faoliyat.value.toLowerCase()))
            }
        }
        else if(this.viloyat!=="Hudud"){
            if(this.faoliyat.value!=="Faoliyat"){
                this.result=this.data.filter((item) => item.faoliyat.toLowerCase().includes(this.faoliyat.value.toLowerCase()))
                return this.result.filter((item) => item.viloyat.toLowerCase().includes(this.viloyat.toLowerCase()))
            }else{
                return this.data.filter((item) => item.viloyat.toLowerCase().includes(this.viloyat.toLowerCase()))
            }
        }
        else{
            return this.data
        }
    },
  },
  methods:{
    faoliyat_fun(arr){
        if(arr==="hodim"){
            return "Tashkilot hodimi"
        }else{
            return 'Band bo\'lmagan fuqaro'
        }
    },
    sertifikat(arr){
        if(arr){
            return 'Olgan'
        }else{
            return 'Olmagan'
        }
    }
  }
}
</script>
<style>
ul.dropdown-menu.show{
    /* transform: translate(0px, 493px)!important; */
    z-index:1!important;
}
.navbar.navbar-expand-lg.navbar-dark{
    z-index: -1!important;
}
</style>