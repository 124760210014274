<template>
    <div class="log-in">

      <section class="bg-image mt-3">
        <div class="mask d-flex align-items-center h-100 gradient-custom-3">
          <div class="container h-100">
            <div class="row d-flex justify-content-center align-items-center h-100">
              <div class="col-12 col-md-9 col-lg-7 col-xl-6">
                <div class="card" style="border-radius: 15px;">
                  <div class="card-body p-5">
                    <h2 class="text-uppercase text-center mb-5">Tizimga kirish</h2>

                    <form v-on:submit.prevent="submitForm">
                      <div class="form-outline mb-4">
                        <input type="text" id="" class="form-control form-control-lg" v-model="username" />
                        <label class="form-label" for="">Loginingizni kiriting</label>
                      </div>

                      <div class="form-outline mb-4">
                        <input type="password" id="" class="form-control form-control-lg" v-model="password" />
                        <label class="form-label" for="">Parolingizni kiriting</label>
                      </div>
                      <div  class="alert alert-danger" role="danger" v-if="errors.length">
                            <h5  
                              v-for="error in errors"
                              v-bind:key="error"
                           >
                              {{error}}
                            </h5>
                      </div>
                      <div class="d-flex justify-content-center">
                        <button type="submit"
                          class="btn btn-primary btn-block btn-lg gradient-custom-4">Tizimga kirish</button>
                      </div>

                      <p class="text-center text-muted mt-5 mb-0">Ro'yhatdan o'tmaganmisiz? 
                        <a href="/sign-up" class="fw-bold text-body"><u> Bu yerdan</u></a> ro'yhatdan o'ting.
                      </p>

                    </form>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    data(){
        return{
            username: '',
            password: '',
            password2: '',
            errors: []
        }
    },
    methods: {
        submitForm() {

            axios.defaults.headers.common['Authorization']=""

            localStorage.removeItem('token');

            this.errors = [];

            if (this.username===''){
                this.errors.push('Username bo\'sh');
            }
            if (this.password===''){
                this.errors.push('parol bo\'sh');
            }
            if (!this.errors.length){
                const formData={
                    username: this.username.toLowerCase(),
                    password: this.password
                }
                axios
                    .post('token/login/', formData)
                    .then(response => {
                        const token=response.data.auth_token

                        this.$store.commit('setToken', token);

                        axios.defaults.headers.common['Authorization']="Token "+token;

                        localStorage.setItem('token', token);

                        this.$router.push('/dashboard/my-account');
                    })
                    .catch(error => {
                        if (error.response){
                            for (const property in error.response.data){
                              this.errors=['Parol yoki Login no\'tog\'ri. Qayta urinib ko‘ring.']
                            }
                        }else if(error.message){
                            this.errors=['Nimadir xato ketdi. Qayta urinib ko‘ring.']

                        }
                    });
            }
        }
    }
}
</script>