<template>
<div class="ms-2 mb-2 me-2 sticky-md-top sticky-top sticky-sm-top" style="margin-bottom: 100;">    
    <nav class="navbar navbar-expand-lg navbar-dark" style="background-color:#8860d0">
        <div class="container-fluid">
          <div class="bd-highlight d-lg-none"><a href="/"><img class="bd-placeholder-img rounded float-start" width="64" height="64" src="../assets/logo/logo-2.png"></a></div>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDarkDropdown" aria-controls="navbarNavDarkDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <nav class="navbar collapse navbar-collapse" id="navbarNavDarkDropdown">
            <ul class="navbar-nav ml-auto ms-3">
              <li class="nav-item">
                <h4>
                  <a href="/" class="nav-link active ms-3">Bosh sahifa</a>
                </h4>
              </li>
              <li class="nav-item">
                <h4>
                  <a class="nav-link ms-3" href="/asoslar/">
                    Me'yoriy huquqiy asoslar
                  </a> 
                </h4>
              </li>
              <li class="nav-item ms-3 dropdown">
                <h4>
                  <a class="nav-link dropdown-toggle" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Ta'lim olish tartibi
                  </a>
                  <ul class="dropdown-menu overlay view dropdown-menu-start " aria-labelledby="navbarDarkDropdownMenuLink">
                    <li v-for="category in talim" :key="category.id">
                      <h4>
                        <a class="dropdown-item rgba-red-strong" :href="'/talim/'+category.slug">{{ category.title }}</a>
                      </h4>
                    </li>
                  </ul>
                </h4>
              </li>
              
              <li class="nav-item ms-3">
                <h4>
                  <a class="nav-link" href="/kurs">  Kurslar  </a>
                </h4>
              </li>
              <li class="ms-3 nav-item dropdown dropdown-menu-start">
                <h4>
                  <a class="nav-link dropdown-toggle" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Axborot resurslari
                  </a>
                  <ul class="dropdown-menu overlay view dropdown-menu-end " aria-labelledby="navbarDarkDropdownMenuLink">
                    <li>
                      <h4>
                        <a class="dropdown-item rgba-red-strong" href="/kitoblar">O'quv adabiyotlar</a>
                      </h4>
                    </li>
                    <li>
                      <h4>
                        <a class="dropdown-item rgba-red-strong" href="/videolar">Media fayllar</a>
                      </h4>
                    </li>
                    
                  </ul>
                </h4>
              </li>
  
              <li class="nav-item ms-3" v-if="$store.state.user.isAuthenticated">
                <h4>
                  <a class="nav-link" href="/ting">  Tinglovchilar </a>
                </h4>
              </li>
              
            </ul>
            <ul class="navbar-nav ml-auto" >
              <li class="nav-item me-2 " v-if="$store.state.user.isAuthenticated">
                <a class="btn btn-primary me-md-2 mx-1" href="/dashboard/my-account">Sahifam</a>
                <a class="btn btn-danger me-md-2" @click="logout()" href="#">Chiqish</a>
              </li>
              <li class="nav-item me-2 " v-else>
                <a class="btn btn-primary me-md-2"  href="/sign-up">Ro'yhatdan o'tish</a>
                <a class="btn btn-success"  href="/log-in">Kirish</a>
              </li>
            </ul>
          </nav>
            
            

          <!-- <a href="/sign-up"><button type="button" class="justify-content-end me-2 btn btn-primary fs-6">Ro'yhatdan o'tish</button></a>
          <a href="/log-in"><button type="button" class="justify-content-end me-2 btn btn-primary fs-6">Tizimga kirish</button></a>
          <a href="/dashboard/my-account"><button type="button" class="justify-content-end me-2 btn btn-primary fs-6">Mening sahifam</button></a>
          <button type="button" class="justify-content-end me-2 btn btn-danger fs-6" @click="logout()">Tizimdan chiqish</button>
     -->
      
        
      </div> 
    </nav>
  </div>

</template>
<script>
import axios from 'axios'
  export default {
    data(){
      return{
        categories:null,
        faoliyatlar:null,
        talim:null,
      }
    },
    mounted(){
      axios
        .get('courses/get_talim_category/')
        .then(response=>{
          this.talim=response.data
        })
    },
    methods: {
      logout(){

        axios.defaults.headers.common['Authorization'] = ''
        localStorage.removeItem('token')
        this.$store.commit('removeToken')
        this.$router.push('/')
      },
    }
  }
document.addEventListener("DOMContentLoaded", function(){
// make it as accordion for smaller screens
if (window.innerWidth < 992) {

  // close all inner dropdowns when parent is closed
  document.querySelectorAll('.navbar .dropdown').forEach(function(everydropdown){
    everydropdown.addEventListener('hidden.bs.dropdown', function () {
      // after dropdown is hidden, then find all submenus
        this.querySelectorAll('.submenu').forEach(function(everysubmenu){
          // hide every submenu as well
          everysubmenu.style.display = 'none';
        });
    })
  });

  document.querySelectorAll('.dropdown-menu a').forEach(function(element){
    element.addEventListener('click', function (e) {
        let nextEl = this.nextElementSibling;
        if(nextEl && nextEl.classList.contains('submenu')) {	
          // prevent opening link if link needs to open dropdown
          e.preventDefault();
          if(nextEl.style.display == 'block'){
            nextEl.style.display = 'none';
          } else {
            nextEl.style.display = 'block';
          }

        }
    });
  })
}
// end if innerWidth
}); 

</script>