<template>
  <div class="signup">
    <section class="h-100 h-custom">
      <div class="container py-5 h-100">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col-lg-8 col-xl-10">
            <div class="card border-light rounded-3">
              <img src=".././assets/logo/logo-2.png" class="d-none d-lg-block mx-auto mt-2" width="250"
                style="border-top-left-radius: .3rem; border-top-right-radius: .3rem;" alt="Sample photo">
              <div class="card-body">
                <h3 class="mb-4 pb-2 pb-md-0 mb-md-5 px-md-2">Ro'yhatdan o'tish uchun to'ldiring</h3>

                <form class="px-md-2" @submit.prevent="submitForm">
                  <div class="row">
                    <div class="col-md-4 mb-3">
                      <div class="form-outline">
                        <input placeholder="Login tanlang" type="text" class="form-control" :style="input_username"
                          v-model="username" />
                      </div>
                    </div>
                    <div class="col-md-4 mb-2">
                      <div class="form-outline d-flex">
                        <input placeholder="Parol tanlang" :type="ps_in_type.type" class="form-control"
                          v-model="password" />
                        <div class="form-control" style="width: 50px">
                          <a style="display:flex;justify-content:center;margin:25%;" @click="password_eye">
                            <i :class="ps_in_type.icon_class" aria-hidden="true"></i></a>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 ">
                      <div class="form-outline d-flex">
                        <input placeholder="Parolni qaytaring" :type="ps_in_type.type" class="form-control"
                          v-model="password2" />
                        <div class="form-control" style="width: 50px">
                          <a style="display:flex;justify-content:center;margin:25%;" @click="password_eye">
                            <i :class="ps_in_type.icon_class" aria-hidden="true"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-around">
                    <div>
                      <p>
                        Login: <br>
                        &#8212; 150 yoki undan kam belgi<br>
                        &#8212; Faqat harflar, raqamlar va shu <br> ( @ , + , - , _ ) belgilarni
                        ishlatish mumkin. <br>
                        &#8212; probel va ortiqcha belgilar<br>
                        ishlatish mumkin emas!
                      </p>
                    </div>
                    <div>
                      <p>
                        Parol: <br>
                      <div ref="sodda">
                        &#8212; sodda, <br>
                      </div>
                      <div ref="username">
                        &#8212; loginga o'xshash,
                      </div>
                      <div ref="belgi">
                        &#8212; 8 ta belgidan kam,
                      </div>
                      <div ref="raqam">
                        &#8212; faqat raqamlarni o'zidan iborat <br>
                      </div>
                      bo'lishi mumkin emas.
                      </p>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-md-4 mb-2">
                      <div class="form-outline">
                        <input placeholder="Ismingiz" type="text" class="form-control" v-model="ism" />
                      </div>
                    </div>
                    <div class="col-md-4 mb-2">
                      <div class="form-outline">
                        <input placeholder="Familiyangiz" type="text" class="form-control" v-model="familiya" />
                      </div>
                    </div>
                    <div class="col-md-4 ">
                      <div class="form-outline">
                        <input placeholder="Otangizni ismi" type="text" class="form-control" v-model="sharif" />
                      </div>
                    </div>
                  </div>

                  <div class="row mt-3">

                    <div class="col-md-4 mb-4">
                      <select class="form-select" v-model="faoliyat">
                        <option disabled value="dis">Faoliyatingizni tanlang</option>
                        <option value="fuqaro">Mustaqil ta'lim oluvchi</option>
                        <option value="hodim">Raxbarlar tarkibi va mas'ul hodimlar</option>
                      </select>
                    </div>
                    <div class="col-md-4 mb-4">
                      <select class="form-select" v-model="viloyat">
                        <option disabled value="dis">Hududingizni tanlang</option>
                        <option value="Toshkent shahri">Toshkent shahri</option>
                        <option value="Toshkent viloyati">Toshkent viloyati</option>
                        <option value="Andijon viloyati">Andijon</option>
                        <option value="Buxoro viloyati">Buxoro</option>
                        <option value="Farg'ona viloyati">Farg'ona</option>
                        <option value="Jizzax viloyati">Jizzax</option>
                        <option value="Xorazm viloyati">Xorazm</option>
                        <option value="Namangan viloyati">Namangan</option>
                        <option value="Navoiy viloyati">Navoiy</option>
                        <option value="Qashqadaryo viloyati">Qashqadaryo</option>
                        <option value="Qoraqalpog'iston Respublikasi">Qoraqalpog'iston Respublikasi</option>
                        <option value="Samarqand viloyati">Samarqand</option>
                        <option value="Sirdaryo viloyati">Sirdaryo</option>
                        <option value="Surxondaryo viloyati">Surxondaryo</option>
                      </select>
                    </div>

                  </div>

                  <div v-show="faoliyat == 'hodim'"> <!-- v-show -->

                    <div class="row">
                      <div class="col-md-4 mb-2">
                        <div class="form-outline">
                          <input placeholder="Tashkilotingiz nomi:" type="text" class="form-control"
                            v-model="tashkilot" />
                        </div>
                      </div>

                      <div class="col-md-4 mb-4">
                        <select class="form-select" v-model="malaka">
                          <option disabled value="dis">Malaka oshirish markazi</option>
                          <option value="fmi">Favqulodda vaziyatlar vazirlig Akademiyasi huzuridagi Fuqaro muhofazasi
                            instituti</option>
                          <option value="hfh">Hududiy Favqulodda vaziyatlar boshqarmasi "Hayot faoliyati xavfsizligi"
                            o'quv markazlari</option>
                        </select>
                      </div>
                    </div>
                    <div class="row mx-auto row-cols-1 row-cols-sm-2 row-cols-md-3 g-3" v-if="malaka == 'fmi'">
                      <div class="form-check " v-for="(i, index) in tabaqa6" :key="i">
                        <input class="form-check-input" type="radio" v-model="tabaqa" :value="'fmi' + (index + 1)"
                          name="flexRadioDefault" :id="'fmi' + i">
                        <label class="form-check-label" :for="'fmi' + i">{{ i }}</label>
                      </div>
                    </div>
                    <div class="row mx-auto row-cols-1 row-cols-sm-2 row-cols-md-3 g-3" v-if="malaka == 'hfh'">
                      <div class="form-check " v-for="(i, index) in tabaqa13" :key="i">
                        <input class="form-check-input" type="radio" v-model="tabaqa" :value="'hfh' + (index + 1)"
                          name="flexRadioDefault" :id="'hfh' + i">
                        <label class="form-check-label" :for="'hfh' + i">{{ i }}</label>
                      </div>
                    </div>
                  </div>
                  <div class="form-check d-flex mt-4 mb-2">
                    <input class="form-check-input me-2" type="checkbox" value="True" id="checkbox" v-model="nom" />
                    <label class="form-check-label" for="checkbox">
                      Tizim shartlariga roziman. <a href="#!" class="text-body"><u>Tizim shartlari.</u></a>
                    </label>
                  </div>
                  <div class="alert alert-danger" role="danger" v-if="errors.length">
                    <p v-for="error in errors" v-bind:key="error">
                      {{ error }}
                    </p>
                  </div>
                  <button type="submit" :class="btn_class">Yuborish</button>

                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      my_btn: false,
      username: '',
      input_username: '',
      btn_class: 'btn btn-success btn-lg mb-1 mt-2',
      password: '',
      password2: '',
      ps_in_type: {
        type: 'password',
        icon_class: 'fa fa-eye-slash'
      },
      familiya: '',
      ism: '',
      sharif: '',
      faoliyat: 'dis',
      malaka: 'dis',
      viloyat: 'dis',
      nom: '',
      tashkilot: '',
      errors: [],
      fbol: false,
      tabaqa: '',
      tabaqa6: [
        'Qoraqalpogʼiston Respublikasi, viloyatlar va Toshkent shahri Favqulodda vaziyatlarning oldini olish va ularni bartaraf etish boʼyicha hududiy komissiya raisi oʼrinbosarlari, aʼzolari hamda tumanva shaharlar komissiya raislari, rais oʼrinbosarlari.',
        'Vazirlik, idora va tashkilotlarning fuqaro muhofazasi boshliqlari, boshliq o‘rinbosarlari',
        'Iqtisodiyot ob’ektlari faoliyatining barqarorligini oshirish komissiyasi raislari va ularning o‘rinbosarlari',
        'Evakuatsiya komissiyasi raislari, ularning o‘rinbosarlari',
        'Vazirliklar va idoralarning fuqaro muhofazasi bo‘yicha mutaxassislari, ishlab chiqarish-iqtisodiyot ob’ektlari fuqaro muhofazasi mutaxassislari va xodimlari',
        'Oliy ta’lim muassasalari professor-o‘qituvchilari'
      ],
      tabaqa13: [
        'Vazirlik, idora va tashkilotlarning fuqaro muhofazasi boshliqlari, boshliq o‘rinbosarlari',
        'Iqtisodiyot ob’ektlari faoliyatining barqarorligini oshirish komissiyasi raislari va ularning o‘rinbosarlari',
        'Evakuatsiya komissiyasi raislari, ularning o‘rinbosarlari',
        'Vazirliklar va idoralarning fuqaro muhofazasi bo‘yicha mutaxassislari, ishlab chiqarish-iqtisodiyot ob’ektlari fuqaro muhofazasi mutaxassislari va xodimlari',
        'Umumta’lim maktablari, akademik litseylar, kasb-hunar, professional ta’lim muassasalari direktorlari, o‘rinbosarlari, maktabgacha ta’lim muassasalari rahbarlari, bolalarni sog‘lomlashtirish oromgohlari va dam olish muassasalari rahbarlari', 'Shahar va tuman FVDT xizmatlari va potensial-xavfli ob’ektlarning navbatchi dispetcherlari',
        'Mahalla fuqarolar yig‘inlari raislari',
        'Tuman va shaharlar FVDT kiruvchi tashkilotlar rahbarlari, tumanlar va shaharlar Favqulodda vaziyatlarni oldini olish va bartaraf etish hududiy komissiyasi a’zolari',
        'Fuqaro muhofazasi tuzilmalari komandirlari',
        'Umumta’lim maktablar fanlari tarkibiga integratsiya qilingan “Hayot xavfsizligi asoslari” o‘quv kursi fani o‘qituvchilari, kasb-hunar, professional ta’lim muassasalari “Hayot faoliyati xavfsizligi” hamda akademik litseylar, umumta’lim maktablarining',
      ],

    }

  },
  mounted() {
    document.title = "Ro'yhatdan o'tish"
  },
  methods: {
    submitForm() {
      this.errors = []

      if (this.username === '') {
        this.errors.push('Login qolib ketdi!')
      } else if (this.password === '') {
        this.errors.push('Parol qolib ketdi!')
      } else if (this.password !== this.password2) {
        this.errors.push('Parollar mos kelmadi')
      } else if (this.ism === '') {
        this.errors.push('Ism qolib ketdi!')
      } else if (this.familiya === '') {
        this.errors.push('Familiya qolib ketdi!')
      } else if (this.sharif === '') {
        this.errors.push('Sharif qolib ketdi!')
      } else if (this.faoliyat === 'dis') {
        this.errors.push('Faoliyatingiz qolib ketdi!')
      } else if (this.viloyat === 'dis') {
        this.errors.push('Viloyatingiz qolib ketdi!')
      } else if (this.nom === '') {
        this.errors.push('Tizim shartlariga roziligingiz qolib ketdi!')
      } else if (this.faoliyat === 'hodim' && this.tashkilot === '') {
        this.errors.push('Tashkilotingizni nomi qolib ketdi!')
      } else if (this.faoliyat === 'hodim' && this.malaka === 'dis') {
        this.errors.push('Malakangiz qolib ketdi!')
      } else if (this.faoliyat === 'hodim' && this.tabaqa === '') {
        this.errors.push('Toifangiz qolib ketdi!')
      }
      else if (!this.errors.length) {
        const formData = {
          djoser: {
            username: this.username.toLowerCase(),
            password: this.password
          },
          my_model: {
            username: this.username.toLowerCase(),
            ism: this.ism,
            familiya: this.familiya,
            sharif: this.sharif,
            faoliyat: this.faoliyat,
            viloyat: this.viloyat,
            tashkilot: this.tashkilot,
            malaka: this.malaka,
            tabaqa: this.tabaqa
          }
        }
        axios
          .post('users/', formData.djoser)
          .then(response => {
            console.log(response.data)
            axios.post('account/sign_up/', formData.my_model)
              .then(response => {
                console.log(response.data)
                if (response.data.status === 'success') {
                  this.$router.push('/log-in')
                }

              })
          })
          .catch(error => {
            if (error.response) {
              console.log(error.response.data);
              for (const property in error.response.data) {
                this.errors.push("${property}: ${error.response.data[property]}")
              }

            } else if (error.message) {
              this.errors.push("Tizimda xatolik. Qayta urinib ko'ring")
            }
          })
        }
    },
    password_eye() {
      if (this.ps_in_type.type === 'password') {
        this.ps_in_type = { type: 'text', icon_class: 'fa fa-eye' }
      } else { this.ps_in_type = { type: 'password', icon_class: 'fa fa-eye-slash' } }
    }
  },
  watch: {
    username(newVal) {
      if (newVal) {
        if (this.password1 && this.password1.length > 4) {
          if (newVal.toLowerCase().indexOf(this.password1.slice(0, 4).toLowerCase()) !== -1) {
            this.$refs.username.setAttribute('class', 'text-negative text-weight-bold');
            this.my_btn = true
          }
          else if (newVal.toLowerCase().indexOf(this.password1.slice(0, 4).toLowerCase()) == -1) {
            this.$refs.username.removeAttribute('class');
            this.my_btn = false
          }
        }
        axios.get("account/check_user/?username=${newVal.toLowerCase()}")
          .then(response => {
            console.log(response.data)
            if (response.data.status === 'user yes') {
              this.errors = []
              this.errors.push('Bunday username mavjud!')
              this.input_username = 'border:2px solid #dc3545;'
              this.btn_class = 'btn btn-success btn-lg mb-1 mt-2 disabled'
            } else if (response.data.status === 'user no') {
              this.errors = []
              this.input_username = ''
              this.btn_class = 'btn btn-success btn-lg mb-1 mt-2'
            }
          })
      }
    },
    password(newVal) {
      if (newVal.length < 8) {
        this.$refs.sodda.setAttribute('class', 'text-negative text-weight-bold');
        this.$refs.belgi.setAttribute('class', 'text-negative text-weight-bold');
        this.my_btn = true
      }
      else if (newVal.length >= 8) {
        this.$refs.sodda.removeAttribute('class');
        this.$refs.belgi.removeAttribute('class');
        this.my_btn = false
      }
      if (!newVal) {
        this.$refs.sodda.removeAttribute('class');
        this.$refs.belgi.removeAttribute('class');
        this.my_btn = true
      }
      if (!isNaN(newVal)) {
        this.$refs.raqam.setAttribute('class', 'text-negative text-weight-bold');
        this.my_btn = true
      }
      if (isNaN(newVal)) {
        this.$refs.raqam.removeAttribute('class');
        this.my_btn = false
      }
      if (this.username && this.username.length > 4) {
        if (newVal.toLowerCase().indexOf(this.username.slice(0, 4).toLowerCase()) !== -1) {
          this.$refs.username.setAttribute('class', 'text-negative text-weight-bold');
          this.my_btn = true
        }
        else if (newVal.toLowerCase().indexOf(this.username.slice(0, 4).toLowerCase()) == -1) {
          this.$refs.username.removeAttribute('class');
          this.my_btn = false
        }
      }
      if (newVal !== this.password2) {
        this.pass_class = "red"
        this.my_btn = true
      }
      else if (newVal == this.password2) {
        this.pass_class = ""
        this.my_btn = false
      }

    },
    password2(newVal) {
      if (newVal !== this.password) {
        this.pass_class = "red"
        this.my_btn = true
      } else {
        this.pass_class = ""
        this.my_btn = false
        if (this.username) {
          if (this.password && this.password.length > 4) {
            if (this.username.toLowerCase().indexOf(this.password.slice(0, 4).toLowerCase()) !== -1) {
              this.$refs.username.setAttribute('class', 'text-negative text-weight-bold');
              this.my_btn = true
            }
            else if (this.username.toLowerCase().indexOf(this.password.slice(0, 4).toLowerCase()) == -1) {
              this.$refs.username.removeAttribute('class');
              this.my_btn = false
            }
          }
        }
      }
    },
  }
}
</script>
<style>
.text-negative {
  color: #C10015;
}

.text-weight-bold {
  font-weight: 700;
}
</style>
<!-- this.username.charAt(0).toUpperCase()+this.username.slice(1), -->