<template>
    <MyModal :on_off="modal">ma'lumotlar yuklanmoqda...</MyModal>
    <main class="tashkilot ms-2 me-2 mt-5" v-if="!data_not_found">
        <div class="row g-10">
            <div class="col-md-3 mx-auto">
            <div class="bg-light d-flex flex-column flex-shrink-0 p-3 bg-light">
                <a href="/" class="link-dark text-decoration-none">
                    <p class="fs-4 ms-2">Bo'limlar</p>
                </a>
                <hr>
                
                <ul class="nav nav-pills flex-column mb-auto">
                <li class="nav-item" v-for='item in faoliyatlar' :key="item.id">
                    <a href="#" @click="setactive(item)" :class="[item.id==activefaoliyat.id ?  'nav-link active': item.id!=activefaoliyat.id, 'nav-link']" aria-current="page">
                    {{item.name}}
                    </a>
                </li>
                </ul>
                <hr>
            </div>
        
            </div>
            <div style="margin: 0; text-indent: 2rem; text-align: justify;"  class="px-5 mx-auto blog-post col-md-9">
                <h3 class="border-bottom text-center">
                    {{activefaoliyat.name}}
                </h3>

                <article class="blog-post" v-html="activefaoliyat.description">
                </article>
            </div>
        </div>
    </main>
    <main v-else class="tashkilot ms-2 me-2 my-5">
        <h1 class="text-center">
            Hozircha bu yerda ma'lumotlar mavjud emas!
        </h1>
    </main>
</template>
<script>
import axios from 'axios'
import MyModal from "@/components/MyModal.vue";
export default {
    components:{
        MyModal
    },
    data(){
        return{
            slug:this.$route.params.slug,
            faoliyatlar:[],
            data_not_found:false,
            activefaoliyat:{
                "id": 1,
                "category_slug": "",
                "name": "",
                "date": "",
                "short_description": "",
                "description": ""
            },
            modal:true
        }
    },
    mounted(){
        this.modal=true
        setTimeout(()=>{
        axios
            .get(`courses/get_talim_by_category/${this.slug}/`)
            .then(response=>{
                if(response.data.length){
                    this.faoliyatlar=response.data
                    this.activefaoliyat=response.data[0]
                    this.modal=false
                }else{
                    this.data_not_found=true
                    this.modal=false
                }
            })},500)
    },
    methods:{
        setactive(qiymat){
            this.activefaoliyat=qiymat
        }
    }
}
</script>