<template>
    <MyModal :on_off="modal">ma'lumotlar yuklanmoqda...</MyModal>
    <div class="courses">
        <div class="ms-2 me-2 mt-2">
            
            <div class="row">
                <!-- <div class="col-md-4">
                    <div class="d-flex flex-column align-items-stretch flex-shrink-0 bg-white" style="width: 380px;">
                        <a href="/" class="d-flex align-items-center flex-shrink-0 p-3 link-dark text-decoration-none border-bottom">
                        <svg class="bi me-2" width="30" height="24"><use xlink:href="#bootstrap"></use></svg>
                        <span class="fs-5 fw-semibold">Qonunlar</span>
                        </a>
                        <div class="list-group list-group-flush border-bottom scrollarea">
                        <a href="#" class="list-group-item list-group-item-action active py-3 lh-tight" aria-current="true">
                            <div class="d-flex w-100 align-items-center justify-content-between">
                            <strong class="mb-1">List group item heading</strong>
                            </div>
                            <div class="col-10 mb-1 small">Some placeholder content in a paragraph below the heading and date.</div>
                        </a>
                        <a href="#" class="list-group-item list-group-item-action py-3 lh-tight">
                            <div class="d-flex w-100 align-items-center justify-content-between">
                            <strong class="mb-1">List group item heading</strong>
                            
                            </div>
                            <div class="col-10 mb-1 small">Some placeholder content in a paragraph below the heading and date.</div>
                        </a>
                        <a href="#" class="list-group-item list-group-item-action py-3 lh-tight">
                            <div class="d-flex w-100 align-items-center justify-content-between">
                            <strong class="mb-1">List group item heading</strong>
                            </div>
                            <div class="col-10 mb-1 small">Some placeholder content in a paragraph below the heading and date.</div>
                        </a>

                        <a href="#" class="list-group-item list-group-item-action py-3 lh-tight" aria-current="true">
                            <div class="d-flex w-100 align-items-center justify-content-between">
                            <strong class="mb-1">List group item heading</strong>
                            
                            </div>
                            <div class="col-10 mb-1 small">Some placeholder content in a paragraph below the heading and date.</div>
                        </a>
                        <a href="#" class="list-group-item list-group-item-action py-3 lh-tight">
                            <div class="d-flex w-100 align-items-center justify-content-between">
                            <strong class="mb-1">List group item heading</strong>
                            </div>
                            <div class="col-10 mb-1 small">Some placeholder content in a paragraph below the heading and date.</div>
                        </a>
                        <a href="#" class="list-group-item list-group-item-action py-3 lh-tight">
                            <div class="d-flex w-100 align-items-center justify-content-between">
                            <strong class="mb-1">List group item heading</strong>
                            
                            </div>
                            <div class="col-10 mb-1 small">Some placeholder content in a paragraph below the heading and date.</div>
                        </a>
                        <a href="#" class="list-group-item list-group-item-action py-3 lh-tight" aria-current="true">
                            <div class="d-flex w-100 align-items-center justify-content-between">
                            <strong class="mb-1">List group item heading</strong>
                            </div>
                            <div class="col-10 mb-1 small">Some placeholder content in a paragraph below the heading and date.</div>
                        </a>
                        <a href="#" class="list-group-item list-group-item-action py-3 lh-tight">
                            <div class="d-flex w-100 align-items-center justify-content-between">
                            <strong class="mb-1">List group item heading</strong>
                            </div>
                            <div class="col-10 mb-1 small">Some placeholder content in a paragraph below the heading and date.</div>
                        </a>
                        <a href="#" class="list-group-item list-group-item-action py-3 lh-tight">
                            <div class="d-flex w-100 align-items-center justify-content-between">
                            <strong class="mb-1">List group item heading</strong>
                            </div>
                            <div class="col-10 mb-1 small">Some placeholder content in a paragraph below the heading and date.</div>
                        </a>
                        <a href="#" class="list-group-item list-group-item-action py-3 lh-tight" aria-current="true">
                            <div class="d-flex w-100 align-items-center justify-content-between">
                            <strong class="mb-1">List group item heading</strong>
                            </div>
                            <div class="col-10 mb-1 small">Some placeholder content in a paragraph below the heading and date.</div>
                        </a>
                        <a href="#" class="list-group-item list-group-item-action py-3 lh-tight">
                            <div class="d-flex w-100 align-items-center justify-content-between">
                            <strong class="mb-1">List group item heading</strong>
                            </div>
                            <div class="col-10 mb-1 small">Some placeholder content in a paragraph below the heading and date.</div>
                        </a>
                        <a href="#" class="list-group-item list-group-item-action py-3 lh-tight">
                            <div class="d-flex w-100 align-items-center justify-content-between">
                            <strong class="mb-1">List group item heading</strong>
                            </div>
                            <div class="col-10 mb-1 small">Some placeholder content in a paragraph below the heading and date.</div>
                        </a>
                        </div>
                    </div>
                </div> -->
                <div class="col-md-10 px-5 mx-auto">
                <h3 class="m-4 pb-4 mb-4 fst-italic border-bottom text-center">
                    {{hujjat.name}}
                </h3>

                <article style="margin: 0; text-indent: 2rem; text-align: justify;" class="blog-post" v-html="hujjat.description">
                </article>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import MyModal from "@/components/MyModal.vue";
export default {
    components:{
        MyModal
    },
    data(){
        return{
            slug:this.$route.params.id,
            hujjat:{
                description:'',
                name:''
            },
            modal:true
        }
    },
    mounted(){
        this.modal=true
        setTimeout(()=>{axios
            .get(`courses/get_hujjat_by_id/${this.slug}/`)
            .then(response=>{
                this.hujjat=response.data
                this.modal=false
            })},500)
    }
}
</script>
