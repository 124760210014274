<template>
    <!-- Footer -->
<footer class="ms-2 me-2" style=" background-color: rgb(122 58 237 / 49%); color: rgb(255 255 255);" >
  <!-- Section: Links  -->

  <div class=" row justify-content-center mt-3 text-center text-lg-start">
    <h1></h1>
    <div class="col-md-1 ">
      <img src="../../public/iframe_html/fmi-logo.png" class="d-none d-lg-block img-fluid my-auto"  alt="..." width="500">
    </div>
    <div class="col-md-3 text-uppercase text-lg-start  text-center ">
        <p>O'zbekiston respublikasi favqulodda <br> vaziyatlar vazirligi akademiyasi huzuridagi <br> Fuqaro Muhofazasi instituti <br>
            AHOLINI ZILZILAGA TAYYORLASH <br/> MILLIY TA'LIM  PLATFORMASI </p>
    </div>

    <div class="col-md-3 text-center ">
      <p><i class="fas fa-home"></i>      Manzil <br> 111221, Toshkent viloyati, Qibray tumani, <br> Geofizika qo'rg'oni, Anaybuloq ko'chasi  3-uy </p>
        <p>
          <i class="fas fa-envelope"></i>
          Telefon: +998 71 230 50 23
        </p>
    </div>
    <div class="col-md-3 text-center ">
      <p><i class="fas fa-home"></i>    E-Manzil: <br> <a href="mailto:igz_mchs@mail.ru">igz_mchs@mail.ru</a><br><a href="mailto:fvvfmi@umail.uz">fvvfmi@umail.uz</a>
      </p>
        <p>
          <i class="fas fa-envelope"></i>
          Sayt IGZ: <a href="https://fvvfmi.uz">fvvfmi.uz</a><br>
          Sayt FVV <a href="https://fvv.uz/uz">fvv.uz</a>
        </p>
    </div>
    <h1></h1>
  </div>
  <!-- Section: Links  -->

  <!-- Copyright -->
  <div class="text-center p-4" style="background-color: rgba(0, 0, 0, 0.05);">
    © 2022 InTech NamMQI
  </div>
  <!-- Copyright -->
</footer>
<!-- Footer -->
</template>