<template>
  <div class="courses">
    <MyModal :on_off="modal">ma'lumotlar yuklanmoqda...</MyModal>
    <div class="ms-2 me-2 mt-2" v-if="is_authenticated">
      <div class="row">
        <div class="col-md-3">
          <div class="d-flex flex-column flex-shrink-0 p-3">
            <a
              href="/"
              class="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none"
            >
              <span class="fs-4 ms-2">Bo'limlar</span>
            </a>
            <hr />
            <ul class="pe-auto nav nav-pills flex-column mb-auto">
              <li
                style="background-color: rgb(78, 7, 207)"
                class="text-white mb-1"
                v-for="(course, index) in courses"
                :key="course"
              >
                <a
                  v-on:click="
                    active_course != index
                      ? (active_course = index)
                      : (active_course = -1)
                  "
                  :class="[
                    active_course == index
                      ? 'text-white nav-link  align-items-center rounded cursor-default'
                      : active_course != index,
                    'nav-link text-white align-items-center rounded collapsed cursor-pointer',
                  ]"
                  data-bs-toggle="collapse"
                  :data-bs-target="'#category' + index"
                  :aria-expanded="[
                    active_course != index ? 'false' : active_course == index,
                    'true',
                  ]"
                  >{{ course.title }}</a
                >
                <div
                  :class="[
                    active_course == index
                      ? 'collapse show'
                      : active_course != index,
                    'collapse',
                  ]"
                  :id="'category' + index"
                  style=""
                >
                  <ul
                    class="btn-toggle-nav list-unstyled fw-normal pb-1 small"
                    style="background-color: #8860d0"
                  >
                    <li
                      class="ms-3 nav-link text-light cursor-pointer hover-shu"
                      v-for="lesson in course.lessons"
                      :key="lesson"
                      @click="setactive(lesson)"
                      :style="[
                        lesson.id == active_lesson.id
                          ? 'background-color: #0d6efd!important;cursor:default;'
                          : lesson.id != active_lesson.id,
                        '',
                      ]"
                      aria-current="page"
                    >
                      <a>
                        {{ lesson.title }}
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-9 px-5">
          <h3 class="m-4 pb-4 mb-4 border-bottom text-center">
            {{ active_lesson.title }}
          </h3>
          <template v-if="active_lesson.lesson_type == 'article'">
            <article
              class="blog-post"
              v-show="article.id == active_lesson.id && !article.bol"
            >
              <div class="d-flex mt-3 mx-auto">
                <button @click="start" class="btn btn-primary mx-auto">
                  Boshlash
                </button>
              </div>
            </article>
            <article
              style="margin: 0; text-indent: 2rem; text-align: justify"
              class="blog-post"
              v-show="article.id == active_lesson.id && article.bol"
              v-html="active_lesson.description"
            ></article>
            <iframe
              v-show="article.id == active_lesson.id && article.bol"
              :src="active_lesson.taqdimot"
              :page="page"
              width="100%"
              height="500px"
            >
            </iframe>
          </template>
          <template v-else-if="active_lesson.lesson_type == 'video'">
            <article
              class="blog-post"
              v-show="article.id == active_lesson.id && !article.bol"
            >
              <div class="d-flex mt-3 mx-auto">
                <button @click="start" class="btn btn-primary mx-auto">
                  Boshlash
                </button>
              </div>
            </article>
            <article
              style="margin: 0; text-indent: 2rem; text-align: justify"
              class="blog-post"
              v-show="article.id == active_lesson.id && article.bol"
              v-html="active_lesson.description"
            ></article>
            <iframe
              v-show="article.id == active_lesson.id && article.bol"
              :src="active_lesson.video"
              width="100%"
              height="500px"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            >
            </iframe>
          </template>
          <template v-else-if="active_lesson.lesson_type == 'end'">
            <h3 class="text-center">
              Siz testni muvaffaqiyatli yakunladingiz. <br />
              <a href="dashboard/my-account">Sahifangizga</a> o'tib sertifikatni
              yuklab olishingiz mumkin.
            </h3>
          </template>
          <template v-else>
            <article
              class="blog-post mx-auto"
              v-show="article.id == active_lesson.id && !article.bol"
            >
              <h3 class="text-center">
                {{ active_lesson.title }} testdan iborat
              </h3>
              <button @click="start" class="btn btn-primary d-block mx-auto">
                Boshlash
              </button>
            </article>
            <div
              class="alert alert-danger"
              role="alert"
              v-show="general_balls >= 0"
            >
              Siz yetarlicha ball to'play olmadingiz qayta urinib ko'ring!
            </div>
            <article
              v-show="article.id == active_lesson.id && article.bol"
              :class="[article.bol ? 'd-flex' : !article.bol, '']"
            >
              <div
                class="mx-auto m-1 col-8"
                v-for="quiz in active_quiz"
                :key="quiz.id"
              >
                <div class="card card-shadow p-1">
                  <p>
                    Diqqat savol:
                    {{ quiz.question }} <br />
                    variantlar: <br />
                  </p>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault1"
                      id="flexRadioDefault1"
                      :value="quiz.op1"
                      v-model="active_answer"
                    />
                    <label class="form-check-label" for="flexRadioDefault1">
                      {{ quiz.op1 }}
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault1"
                      id="flexRadioDefault2"
                      :value="quiz.op2"
                      v-model="active_answer"
                    />
                    <label class="form-check-label" for="flexRadioDefault2">
                      {{ quiz.op2 }}
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault1"
                      id="flexRadioDefault3"
                      :value="quiz.op3"
                      v-model="active_answer"
                    />
                    <label class="form-check-label" for="flexRadioDefault3">
                      {{ quiz.op3 }}
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault1"
                      id="flexRadioDefault4"
                      :value="quiz.op4"
                      v-model="active_answer"
                    />
                    <label class="form-check-label" for="flexRadioDefault4">
                      {{ quiz.op4 }}
                    </label>
                  </div>
                  <div
                    :class="[
                      active_index == 0 ? 'd-flex' : active_index > 0,
                      'd-flex ',
                    ]"
                  >
                    <h3 v-show="general_balls >= 0">
                      Jami ball:{{ general_balls }}
                    </h3>
                    <button
                      class="ms-2 btn btn-danger"
                      v-show="general_balls >= 0"
                      @click="restart_test"
                    >
                      Qayta boshlash
                    </button>
                    <button
                      class="btn btn-success"
                      v-show="active_index != 0 && general_balls < 0"
                      @click="previous_quiz"
                    >
                      Orqaga
                    </button>
                    <button
                      class="ms-2 btn btn-primary"
                      v-show="active_index != quizzes.length - 1"
                      @click="next_quiz"
                    >
                      Oldinga
                    </button>
                    <button
                      class="ms-2 btn btn-danger"
                      v-show="
                        active_index == quizzes.length - 1 && general_balls < 0
                      "
                      @click="send_quiz"
                    >
                      Jo'natish
                    </button>
                    <!-- active_index==quizzes.length-1 -->
                  </div>
                </div>
              </div>
            </article>
            <article class="blog-post" v-show="article.bol != 1"></article>
          </template>
        </div>
      </div>
    </div>
    <main v-else>
      <course-copy />
    </main>
  </div>
</template>
<script>
import axios from "axios";

import CourseCopy from "@/views/CourseCopy.vue";
import MyModal from "@/components/MyModal.vue";
export default {
  components: {
    CourseCopy,
    MyModal,
  },
  data() {
    return {
      modal: true,
      courses: null,
      active_lesson: {
        id: 1,
        title: "",
        slug: "",
        lesson_type: "",
      },
      active_course: 0,
      active_index: 0,
      balls: 0,
      answers: [],
      article: {
        id: 0,
        bol: false,
      },
      articles: [],
      active_quiz: [],
      active_answer: null,
      general_balls: -1,
      quizzes: [],
      no_save: null,
    };
  },
  computed: {
    is_authenticated() {
      return this.$store.state.user.isAuthenticated;
    },
  },
  mounted() {
    if (this.is_authenticated) {
      setTimeout(() => {
        axios.get(`courses/get_category_course_lesson/`).then((response) => {
          this.courses = response.data;
          this.active_lesson = response.data[0].lessons[0];
          if (this.active_lesson.lesson_type === "quiz") {
            if (this.article.bol !== true && this.active_quiz.length === 0) {
              axios
                .get(`courses/get_quiz_by_lesson/${this.active_lesson.slug}/`)
                .then((response) => {
                  this.quizzes = response.data;
                  this.active_quiz = [response.data[0]];
                  this.modal = false;
                });
            }
          }
          if (this.articles.indexOf(this.active_lesson.id) === -1) {
            this.article.id = this.active_lesson.id;
            this.article.bol = false;
            this.modal = false;
          } else {
            this.article.id = this.active_lesson.id;
            this.article.bol = true;
            this.modal = false;
          }
          //   console.log(response.data[0].lessons);
        });
      }, 500);
    }
  },
  methods: {
    setactive(qiymat) {
      this.active_lesson = qiymat;
      if (this.active_lesson.lesson_type === "quiz") {
        console.log("quiz");
        axios
          .get(`courses/get_quiz_by_lesson/${this.active_lesson.slug}/`)
          .then((response) => {
            this.quizzes = response.data;
            this.active_quiz = [response.data[0]];
          });
      }
      if (this.articles.indexOf(this.active_lesson.id) === -1) {
        this.article.id = this.active_lesson.id;
        this.article.bol = false;
      } else {
        this.article.id = this.active_lesson.id;
        this.article.bol = true;
      }
    },
    next_quiz() {
      if (this.active_answer) {
        if (this.active_index <= this.answers.length - 1) {
          this.answers[this.active_index] = this.active_answer;
          this.active_index += 1;
          this.active_answer = this.answers[this.active_index];
          if (this.active_answer === undefined) {
            this.active_answer = this.no_save;
          }
          this.active_quiz = [this.quizzes[this.active_index]];
        } else if (this.active_index === this.answers.length) {
          this.active_index += 1;
          this.answers.push(this.active_answer);
          this.active_quiz = [this.quizzes[this.active_index]];
          this.active_answer = "";
        }
      }
    },

    previous_quiz() {
      if (this.active_index === this.answers.length) {
        this.no_save = this.active_answer;
      }
      this.active_index -= 1;
      this.active_quiz = [this.quizzes[this.active_index]];
      this.active_answer = this.answers[this.active_index];
    },
    send_quiz() {
      if (this.active_answer) {
        this.answers.push(this.active_answer);
        this.answers.forEach((item, index) => {
          let ans = this.quizzes[index].answer;
          if (item === ans) {
            this.balls += 1;
          }
          console.log(index, ".Savol javobi:");
          console.log("User answer:", item);
          console.log("----------------------------------------------");
          console.log("Correct answer:", this.quizzes[index].answer);
          console.log("----------------------------------------------");
        });
        console.log((100 / this.quizzes.length) * this.balls);
        this.general_balls = (100 / this.quizzes.length) * this.balls;
        if (this.general_balls > 56) {
          console.log("ishladi");
          axios.get("account/increment_balls/").then((response) => {
            if (response.data.status === "success") {
              this.active_lesson.lesson_type = "end";
            }
          });
        }
      }
    },
    restart_test() {
      this.active_index = 0;
      this.active_quiz = [];
      this.answers = [];
      this.general_balls = -1;
      this.balls = 0;
      this.active_quiz = [this.quizzes[0]];
    },
    start() {
      this.article.bol = true;
      this.articles.push(this.active_lesson.id);
    },
  },
};
</script>
<style>
.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}
.hover-shu:hover {
  background: #0000004a !important;
}
</style>
