<template>
  <MyModal :on_off="modal">ma'lumotlar yuklanmoqda...</MyModal>
  <div class="courses">
    <div class="ms-5 me-5 mt-2">
      <div class="row">
        <div
          class="col-4 d-flex flex-column flex-shrink-0 p-3 bg-light"
          style="width: 280px"
        >
          <a
            href="/"
            class="
              d-flex
              align-items-center
              mb-3 mb-md-0
              me-md-auto
              link-dark
              text-decoration-none
            "
          >
            <svg class="bi pe-none me-2" width="40" height="32">
              <use xlink:href="#bootstrap"></use>
            </svg>
            <span class="fs-4">Filtr</span>
          </a>
          <hr />

          <ul class="nav nav-pills flex-column mb-auto">
            <li v-for="item in category" :key="item.id">
              <a href="#" @click="setactive(item)" :class="[item.id==activefaoliyat.id ?  'nav-link active': item.id!=activefaoliyat.id, 'nav-link link-dark']" aria-current="page">
                {{item.title}}
              </a>
            </li>
          </ul>
          <hr />
        </div>

        <div class="col-8">
          <div class="row row-cols-1 row-cols-md-3 g-3">
            <div class="col mt-2" v-for="i in video" :key="i">
              <div class="card">
                  <img class="mx-1" :src="i.get_poster_url" alt="" @click="playVideo(i.id)" v-show="videoplay!=i.id" width="300" height="220">
                <video controls class="mx-2 mt-3" :poster="i.get_poster_url" v-show="videoplay==i.id">
                  <source :src="i.get_video_url" type="video/mp4">
                </video>
                <div class="card-body">
                  <h5 class="card-title">{{i.name}} - Card title</h5>
                  <p class="card-text">{{i.description}}</p>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios'
import MyModal from "@/components/MyModal.vue";
export default {
    components:{
        MyModal
    },
  data(){
    return{
      category:[{slug:''}],
      activefaoliyat:{
                "id": 1,
                title:'',
                slug:''
      },
      video:[],
      baseurl:axios.defaults.baseURL.slice(0,-8),
      videoplay:null,
      modal:true
      }
  },
  mounted() {
    this.modal=true
    setTimeout(()=>{      
      axios.get('/courses/get_video_category/')
      .then(response=>{
        this.category = response.data
        this.activefaoliyat=response.data[0]
        axios
        .get(`courses/get_video_by_category/${response.data[0].slug}/`)
        .then(response=>{
            this.modal=false
            this.video=response.data
        })
      })
    },500)
    
  },
  methods:{
        setactive(qiymat){
          axios
        .get(`courses/get_video_by_category/${qiymat.slug}/`)
        .then(response=>{
            this.activefaoliyat=qiymat
            this.video=response.data
        })
        },
        downloadFile(url) {
              axios({
                    url: url, // File URL Goes Here
                    method: 'GET',
                    responseType: 'blob',
                }).then((res) => {
                     var FILE = window.URL.createObjectURL(new Blob([res.data]));
                     
                     var docUrl = document.createElement('a');
                     docUrl.href = FILE;
                     let filename=url.substring(url.lastIndexOf('/')+1)
                     docUrl.setAttribute('download', filename);
                     document.body.appendChild(docUrl);
                     docUrl.click();
                });
          },
        playVideo(id){
          this.videoplay=id;
        }
    }
};
</script>
