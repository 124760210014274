<template>
    <MyModal :on_off="modal">ma'lumotlar yuklanmoqda...</MyModal>
    <div class="d-flex justify-content-center">
       <iframe class="mx-auto" src="/iframe_html/index.html" width="1350" height="900" id="f" name="myIframe" scrolling="yes"></iframe>
    </div>
</template>
<script>
import MyModal from "@/components/MyModal.vue";
export default {
    components:{
        MyModal
    },
    data(){
        return{
            unique_key:this.$route.params,
            modal:true
        }
    },
    mounted(){
        window.localStorage.removeItem('unique_key')
        window.localStorage.setItem('unique_key',JSON.stringify(this.$route.params));
        this.modal=true
        setTimeout(()=>{
            this.modal=false
        },2000)
    }

}
</script>