<template>
  <div>
    <!--bu header-->
    <div class="ms-5 me-5">
      <pre class="chroma" style="margin-bottom: -2rem; margin-top: -2rem">
              <div class="mt-2 d-flex justify-content-center bd-highlight">
                  <div class="mt-2 p-2 bd-highlight d-none d-lg-block"><img class="bd-placeholder-img rounded float-start " width="250" height="250" src="./assets/logo/logo-2.png"></div>
                  <div class="bd-highlight d-none d-lg-block">
                    <h1 style="font-family: 'Times New Roman', Times, serif;" class="display-10 text-uppercase text-center">AHOLINI ZILZILAGA TAYYORLASH<br/>Milliy ta'lim  platformasi</h1><br>
                      <h4 style="font-family: 'Times New Roman', Times, serif;"><marquee>Ilm o'tda yonmaydigan, suvda cho'kmaydigan, hech kim sizdan tortib ololmaydigan boylik ekanini aslo unutmang</marquee></h4>
                    </div>
                  <div class="mt-2 p-2 bd-highlight d-none d-xl-block"><img class="bd-placeholder-img rounded float-start " width="250" height="250" src="./assets/logo/logo-fvv.png"></div>
              </div>
          </pre>
    </div>

    <Nav />

    <router-view />
    <Footer />
  </div>
</template>
<script>
import axios from "axios";

import Nav from "@/components/Nav";
import Footer from "@/components/Footer";
export default {
  name: "App",
  components: {
    Nav,
    Footer,
  },
  beforeCreate() {
    this.$store.commit("initializeStore");

    const token = this.$store.state.user.token;

    if (token) {
      axios.defaults.headers.common["Authorization"] = "Token " + token;
    } else {
      axios.defaults.headers.common["Authorization"] = "";
    }
  },
};
</script>
