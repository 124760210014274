import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SignUp from '../views/SignUp.vue'
import LogIn from '../views/LogIn.vue'
import MyAccount from '../views/dashboard/MyAccount.vue'
import Organ from "../views/Organ.vue"
import QonunlarDavomi from "../views/qonunlar-davomi.vue"
import Qonunlar from "../views/qonunlar.vue"
import TashkilotUchun from "../views/tashkilot-uchun.vue"
import Kitoblar from "../views/kitoblar.vue"
import Videolar from "../views/video.vue"
import Course from "../views/Course.vue"
import News from "../views/News.vue"
import Cert from "../views/Cert.vue"
import Tinglovchilar from"@/views/Tinglovchilar"

const routes = [
  {
    path: '/ting',
    name: 'ting',
    component: Tinglovchilar
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/dashboard/my-account',
    name: 'myaccount',
    component: MyAccount
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    component: SignUp
  },
  {
    path: '/log-in',
    name: 'log-in',
    component: LogIn
  },
  {
    path: '/organ',
    name: 'organ',
    component: Organ
  },
  {
    path: '/hujjat/:id',
    name: 'qonunlar-davomi',
    component: QonunlarDavomi
  },
  {
    path: '/asoslar/',
    name: 'asoslar',
    component: Qonunlar
  },
  {
    path: '/talim/:slug',
    name: 'tashkilot-uchun',
    component: TashkilotUchun
  },
  {
    path: '/kitoblar',
    name: 'kitoblar',
    component: Kitoblar
  },
  {
    path: '/videolar',
    name: 'videolar',
    component: Videolar
  },
  {
    path: '/:cat',
    name: 'category',
    component: HomeView
  },
  {
    path: '/kurs',
    name: 'course',
    component: Course
  },
  {
    path: '/news/:slug',
    name: 'news',
    component: News
  },
  {
    path: '/sert/:unique_key',
    name: 'sert',
    component: Cert
  },
  {
    path:'/:pathMatch(.*)*',
    name:'not-found',
    component:()=>import("@/views/404.vue")
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
