<template>
  <div class="home">
    <div class="ms-2 me-2 mt-3 mb-4">
      <main>
        <div class="container text-center my-3">
        <div class="row mx-auto my-auto justify-content-center">
          <div id="recipeCarousel" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner" role="listbox">
              <div class="carousel-item active">
                <div class="col-md-6">
                  <div class="card border border-white mx-4">
                    <div class="card-img " style="">
                      <img :src=img1 class="img-fluid">
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="col-md-6">
                  <div class="card border border-white mx-4">
                    <div class="card-img">
                      <img :src=img2 class="img-fluid">
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="col-md-6">
                  <div class="card border border-white mx-4">
                    <div class="card-img">
                      <img :src=img3 class="img-fluid">
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="col-md-6">
                  <div class="card border border-white mx-4">
                    <div class="card-img">
                      <img :src=img4 class="img-fluid">
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="col-md-6">
                  <div class="card border border-white mx-4">
                    <div class="card-img">
                      <img :src=img5 class="img-fluid">
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="col-md-6">
                  <div class="card border border-white mx-4">
                    <div class="card-img">
                      <img :src=img6 class="img-fluid">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a class="carousel-control-prev bg-transparent w-aut" href="#recipeCarousel" role="button" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            </a>
            <a class="carousel-control-next bg-transparent w-aut" href="#recipeCarousel" role="button" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
            </a>
          </div>
        </div>		
        </div>
        </main>
    </div>
  </div>
</template>
<style lang="scss">
.card border border-white {
  border-color: #ffff;
}
@media (max-width: 767px) {
		.carousel-inner .carousel-item > div {
			display: none;
		}
		.carousel-inner .carousel-item > div:first-child {
			display: block;
		}
	}

	.carousel-inner .carousel-item.active,
	.carousel-inner .carousel-item-next,
	.carousel-inner .carousel-item-prev {
		display: flex;
	}


	@media (min-width: 768px) {

		.carousel-inner .carousel-item-end.active,
		.carousel-inner .carousel-item-next {
			transform: translateX(25%);
		}

		.carousel-inner .carousel-item-start.active, 
		.carousel-inner .carousel-item-prev {
			transform: translateX(-25%);
		}
	}

	.carousel-inner .carousel-item-end,
	.carousel-inner .carousel-item-start { 
		transform: translateX(0);
	}

  
  .carousel-item{
  cursor: -webkit-grabbing;
    
    & img{
      border-radius: 30px;
      
    transition: .25s linear;  
    }}
  

  @media only screen and (max-width: 600px)
  {
    .carousel-item img{
      height: auto;
    }
  }



</style>

<script>

import axios from "axios";
import SideBar from "@/components/SideBar";
export default {
  name: "HomeView",
  components: {
    SideBar,
  },
  data() {
    return {
      posts: [],
      total_page: 0,
      current_page: 1,
      publicPath: process.env.BASE_URL,
      img1: require("../assets/carusel/1.jpg"),
      img2: require("../assets/carusel/2.jpg"),
      img3: require("../assets/carusel/3.jpg"),
      img4: require("../assets/carusel/4.jpg"),
      img5: require("../assets/carusel/5.jpeg"),
      img6: require("../assets/carusel/6.jpeg"),

    };
  },

  methods: {
    truncate(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
    next_page(item){
      this.current_page=item
      axios.get(`courses/get_all_blogs/?page=${item}`).then((response) => {
      this.total_page = response.data.total_page;
      this.current_page = response.data.current_page;
      this.posts = response.data.results;
    });
    }
  },
  mounted(){
  let items = document.querySelectorAll('.carousel .carousel-item')

		items.forEach((el) => {
			const minPerSlide = 2
			let next = el.nextElementSibling
			for (var i=1; i<minPerSlide; i++) {
				if (!next) {
            // wrap carousel by using first child
            next = items[0]
        }
        let cloneChild = next.cloneNode(true)
        el.appendChild(cloneChild.children[0])
        next = next.nextElementSibling
    }
})
    }
};
</script>