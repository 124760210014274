<template>
  <MyModal :on_off="modal">ma'lumotlar yuklanmoqda...</MyModal>
  <div class="kitoblar ms-2 me-2 mt-2">
      <div class="row g-10">
        <div class="col-md-2 me-auto ">
          <div class="d-flex flex-column flex-shrink-0 p-3 bg-light">
          <a href="/" class="link-dark text-decoration-none " >
            <span class="fs-4 ms-2">Filtr</span>
          </a>
          <hr />

          <ul class="nav nav-pills flex-column mb-auto">
            <li class="nav-item" v-for="item in category" :key="item.id">
              <a href="#" @click="setactive(item)" :class="[ item.id == activefaoliyat.id ? 'nav-link active' : item.id != activefaoliyat.id, 'nav-link link-dark', ]" aria-current="page" >
                {{ item.title }}
              </a>
            </li>
          </ul>
          <hr />
        </div>
        </div>
        <div class="col-md-10 px-5 me-auto blog-post ">
          <div class="border d-flex align-items-center mt-2" v-for="i, index in kitoblar" :key="i" >
            <div class="flex-shrink-0 d-none d-lg-block">
              <div style="position: relative; text-align: center; color: white">
                <img src="../assets/kitob.svg" />

                <div class="col-5" style=" font-size: 0.575vw; word-wrap: break-word; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); " >
                  {{ i.name }}
                </div>
              </div>
            </div>
            <div class="flex-grow-1 ms-3">
              <div class="row mx-auto">
                <p class="mt-3">
                  <b>{{ i.name }}</b>
                </p>
                <hr />
                <div class="col-5">
                  <table class="table d-none d-lg-block">
                    <tbody>
                      <tr>
                        <td>Avtor</td>
                        <td>{{ i.autor }}</td>
                      </tr>
                      <tr>
                        <td>Chop etilgan yili</td>
                        <td>{{ i.date }} yil</td>
                      </tr>
                      <tr>
                        <td>Resurs turi</td>
                        <td>{{ i.category_name }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-12 mb-2 ">
                  <p class="card-text d-none d-lg-block">
                    {{ i.description }}
                  </p>
                  <button
                  type="button" class="btn btn-primary" data-toggle="modal" :data-target="'#exampleModalLong'+index"
                  >
                    Ko'rish
                  </button>

                  <!-- Modal -->
                  <div class="modal fade bd-example-modal-xl" :id="'exampleModalLong'+index"  role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                    <div class="modal-dialog modal-xl" role="document">
                      <div class="modal-content ">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLongTitle">{{ i.name }}</h5>
                        </div>
                        <div class="modal-body row justify-content-center">
                          <iframe class="justify-content-center" :src="baseurl+i.kitob_pdf" frameborder="0"
                            style="width: 70rem; height: 47rem"
                          ></iframe>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" data-dismiss="modal">Yopish</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>  
    </div>
  
</template>
<script>
import axios from "axios";
import MyModal from "@/components/MyModal.vue";
export default {
    components:{
        MyModal
    },
  data() {
    return {
      category: [{ slug: "" }],
      activefaoliyat: {
        id: 1,
        title: "",
        slug: "",
      },
      kitoblar: [],
      baseurl: axios.defaults.baseURL.slice(0, -8),
      modal:true
    };
  },
  mounted() {
    this.modal=true
        setTimeout(()=>{
    axios.get("/courses/get_kitob_category/").then((response) => {
      this.category = response.data;
      this.activefaoliyat = response.data[0];
      axios
        .get(`courses/get_kitob_by_category/${response.data[0].slug}/`)
        .then((response) => {
          this.kitoblar = response.data;
          this.modal=false
        });
    });
  },500)
  },
  methods: {
    setactive(qiymat) {
      axios
        .get(`courses/get_kitob_by_category/${qiymat.slug}/`)
        .then((response) => {
          this.activefaoliyat = qiymat;
          this.kitoblar = response.data;
        });
    },
    downloadFile(url) {
      axios({
        url: url, // File URL Goes Here
        method: "GET",
        responseType: "blob",
      }).then((res) => {
        console.log(res);
        var FILE = window.URL.createObjectURL(new Blob([res.data]));
        var docUrl = document.createElement("a");
        docUrl.href = FILE;
        let filename = url.substring(url.lastIndexOf("/") + 1);
        docUrl.setAttribute("download", filename);
        document.body.appendChild(docUrl);
        docUrl.click();
      });
    },
  },
};
</script>
