<template>
    <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" >
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
            <div class="modal-body">
                <div class="d-flex justify-content-center my-5">
                    <div class="spinner-border" role="status">
                    </div>
                </div>
                <h4 class="d-flex justify-content-center"><slot></slot></h4>
            </div>
            </div>
        </div>
    </div>
</template>
<script>
export default{
    props:{
        on_off:Boolean
    },
    mounted(){
        if (this.on_off){
            $('#exampleModalCenter').modal('show')
        }else{
            $('#exampleModalCenter').modal('hide')
        }
    },
    watch:{
        on_off(val){
            if (val){
                $('#exampleModalCenter').modal('show')
            }else{
                $('#exampleModalCenter').modal('hide')
            }
        }
    }
}
</script>