import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import axios from 'axios'
const root_url='http://api.zilzilaplatforma.fvvfmi.uz'
// const root_url='http://127.0.0.1:8000'
axios.defaults.baseURL = `${root_url}/api/v1/`
axios.defaults.withCredentials = false
const app=createApp(App)
app.use(store).use(router, axios).mount('#app')
app.config.globalProperties.BASE_URL = root_url;
window.localStorage.removeItem('BASE_URL')
window.localStorage.setItem('BASE_URL',JSON.stringify(root_url));