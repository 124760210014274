<template>
  <MyModal :on_off="modal">ma'lumotlar yuklanmoqda...</MyModal>
  <div class="mt-3 myaccount ms-2 me-2 text-center" style="font-family: 'Times New Roman',' Times', 'serif'">
    <h1 class="mt-5">Ta'lim platformasiga xush kelibsiz {{user.familiya}}! </h1>
        <!-- <li class="nav-item">
          <a :class="class1" aria-current="page" @click="class1='nav-link active';class2='nav-link dark'" href="#">Bitirilgan kurslar</a>
        </li>
        <li class="nav-item">
          <a :class="class2" aria-current="page" @click="class2='nav-link active';class1='nav-link dark'" href="#">Mening sertikatlarim</a>
        </li> -->
        <main class="container">
          <div class="row row-cols-1 row-cols-md-1 mb-2 mt-4 justify-content-center">
            <div class="col">
              <table class="table text-start">
                <thead>
                  <tr>
                    
                    <th scope="col">FISH</th>
                    <td scope="col">{{user.familiya}} {{user.ism}} {{user.sharif}}</td>
                    
                  </tr>
                </thead>
                <tbody>
                  <tr v-show="user.tabaqa_display!='fuqaro'">
                    <th>Tashkilot nomi</th>
                    <td>{{user.tash_nomi}}</td>
                  </tr>
                  <tr>
                  
                    <th class="text-wrap">Malaka oshirish markazi</th>
                    <td>{{user.viloyat}} XFX</td>
                    
                  </tr>
                  <tr>
                    
                    <th>Lavozimi</th>
                    <td>{{user.faoliyat}}</td>
                    
                  </tr>
                  <tr>

                    <th>Viloyat</th>
                    <td>{{user.viloyat}}</td>

                  </tr>
                  <tr>
                    
                    <th scope="col">Toifa</th>
                    <td scope="col text-wrap" style="width: 30rem;">{{user.tabaqa}}</td>
                    
                  </tr>
                </tbody>
              </table>
            </div>
            
          </div>

<div class="row row-cols-1 row-cols-md-3 mb-3 text-center">
            <div class="col">
              <div class="card mb-4 rounded-3 shadow-sm">
                <div class="card-header py-3">
                  <h4 class="my-0 fw-normal">Ta'lim olish tartibi</h4>
                </div>
                <div class="card-body">
                  <ul class="list-unstyled mt-3 mb-4">
                    <li class="text-wrap"><b>Foydlanish bo'yicha yo'riqnomalar ushbu bo'limda joylashgan.</b></li>
                  </ul>
                  <button type="button" class="w-100 btn btn-lg btn-outline-primary" @click="routing_talim">Tanishib chiqish</button>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card mb-4 rounded-3 shadow-sm">
                <div class="card-header py-3">
                  <h4 class="my-0 fw-normal">Kurslar</h4>
                </div>
                <div class="card-body">
                  <ul class="list-unstyled mt-3 mb-4">
                    <li class="text-wrap"><b>O'rganilishi kerak bo'lgan bo'limlar ushbu sinfxonada joylashgan.</b></li>
                  
                  </ul>
                  <button type="button" class="w-100 btn btn-lg btn-outline-primary" onclick="window.open('/kurs','_self')">Sinfhonaga o'tish</button>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card mb-4 rounded-3 shadow-sm border-danger">
                <div class="card-header py-3 text-white bg-danger border-primary">
                  <h4 class="my-0 fw-normal">Sertifikat</h4>
                </div>
                <div class="card-body">
                  <ul class="list-unstyled mt-3 mb-4">
                    <li class="text-wrap"><b>Kurs tugallanganidan so'ng o'z sertifiatingizni ushbu havola orqali yuklab olishingiz mumkin.</b></li>
                  </ul>
                  <a :href="'/sert/'+user.unique_key" :class="[user.sertifikat_bool==false?'w-100 btn btn-lg btn-danger disabled':user.sertifikat_bool==true,'w-100 btn btn-lg btn-danger']">Sertifikatimni ko'rish</a>
                </div>
              </div>
            </div>
          </div>
        </main>
      
    
    <button @click="logout()" class="btn btn-danger mt-2">Tizimdan chiqish</button>
  </div>
</template>
<script>
import axios from 'axios'
import MyModal from "@/components/MyModal.vue";
export default {
    components:{
        MyModal
    },
  mounted(){
    this.modal=true
    setTimeout(()=>{
      axios
      .get("account/get_user_info/")
      .then(response=>{
        this.user=response.data
        this.modal=false
      })
    },500)
  },
  methods: {
    routing_talim(){
      if(this.user.tabaqa_display==='fuqaoro'){
        window.open('/talim/tarkib-2','_self')
      }else{
        window.open('/talim/tarkib-1','_self')
      }
    },
    logout(){
      axios.defaults.headers.common['Authorization'] = ""

      localStorage.removeItem('token')
      
      this.$store.commit('removeToken')

      this.$router.push('/')
    }
  },
  data () {
    return {
      class1:'nav-link active',
      class2:'nav-link dark',
      user:{
        id:null,
        user:null,
        ism:null,
        familiya:null,
        sharif:null,
        faoliyat:null,
        viloyat:null,
        tash_nomi:null,
        malaka:null,
        tabaqa:null,
        unique_key:null,
        sertifikat_bool:null,
        tabaqa_display:null
      },
      modal:true
    }
  }
}
</script>