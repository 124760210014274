<template>
  <div class="home">
    <div class="ms-5 me-5 mt-3 mb-4">
      <div class="row mb-2 g-6">
        <div class="col-md-10 mx-auto">
          <div class="d-flex">
            <div class="" v-for="post in posts" :key="post.id">
              
              <article class="blog-post">
                <h2 style="font-family: 'Times New Roman', Times, serif;" class="text-center">{{post.title}}</h2>
                <p class=""><small class="ms-4 blog-post-meta text-muted">{{post.created_at}}</small></p> 
                <div style="margin:0; text-indent: 2rem;text-align: justify; " v-html="post.description"></div>
                
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';
import SideBar from '@/components/SideBar'
export default {
  name: 'NewsView',
  components:{
    SideBar
  },
  data(){
    return{
      posts:[],
      // category:$route.params.cat
    }
  },
  mounted(){
    axios.get(`courses/get_blog_by_slug/${this.$route.params.slug}`)
    .then(response=>{
      this.posts=[response.data]
    })
  },
  methods:{
    truncate(text, length, suffix) {
            if (text.length > length) {
                return text.substring(0,length) + suffix;
            } else {
                return text;
            }
    },
  },
}
</script>