<template>
    <div class="courses">
        <div class="ms-5 me-5 mt-2 container">
            <div class="row">
                <div class="col-4 d-flex flex-column flex-shrink-0 p-3 bg-light" style="width: 280px;">
                    <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
                    <svg class="bi pe-none me-2" width="40" height="32"><use xlink:href="#bootstrap"></use></svg>
                    <span class="fs-4">Kurslar</span>
                    </a>
                    <hr>
                    
                    <ul class="nav nav-pills flex-column mb-auto">
                    <li v-for="item in category" :key="item.id">
                        <a href="#" @click="setactive(item)" :class="[item.id==activefaoliyat.id ?  'nav-link active': item.id!=activefaoliyat.id, 'nav-link link-dark']" aria-current="page">
                            {{item.title}}
                        </a>
                    </li>
                    </ul>
                    <hr>
                </div>
               <div class="col-8">
                   <div class="row row-cols-1 row-cols-md-3 g-4">
                    <div class="col" v-for="i in 6" :key="i">
                        <div class="card h-100">
                        <svg class="bd-placeholder-img card-img-top" width="100%" height="140" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Image cap" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#868e96"></rect><text x="50%" y="50%" fill="#dee2e6" dy=".3em">Image cap</text></svg>

                        <div class="card-body">
                            <h5 class="card-title">Card title</h5>
                            <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                            <a href="#" class="btn btn-primary">Ko'rish</a>
                        </div>
                        </div>
                    </div>
                    
                    </div>
                    <div class="mt-5 col-12">
                    <nav aria-label="...">
                        <ul class="pagination">
                            <li class="page-item disabled">
                            <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
                            </li>
                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                            <li class="page-item active" aria-current="page">
                            <a class="page-link" href="#">2</a>
                            </li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item">
                            <a class="page-link" href="#">Next</a>
                            </li>
                        </ul>
                    </nav>
                </div>
                </div>
                
            </div>
        </div>
        </div>
    
</template>
<script>
import axios from 'axios'
export default {
  data(){
    return{
      category:[{slug:''}],
      activefaoliyat:{
                "id": 1,
                title:'',
                slug:''
      },
      courselar:[],
      baseurl:axios.defaults.baseURL.slice(0,-8)
      }
  },
  mounted() {
    axios.get('/courses/get_course_category/')
    .then(response=>{
      this.category = response.data
      this.activefaoliyat=response.data[0]
      axios
      .get(`courses/get_course_by_category/${response.data[0].slug}/`)
      .then(response=>{
          this.courselar=response.data
      })
    })
    
  },
  methods:{
        setactive(qiymat){
          axios
        .get(`courses/get_course_by_category/${qiymat.slug}/`)
        .then(response=>{
            this.activefaoliyat=qiymat
            this.kitoblar=response.data
        })
        },
        downloadFile(url) {
              axios({
                    url: url, // File URL Goes Here
                    method: 'GET',
                    responseType: 'blob',
                }).then((res) => {
                     var FILE = window.URL.createObjectURL(new Blob([res.data]));
                     
                     var docUrl = document.createElement('a');
                     docUrl.href = FILE;
                     let filename=url.substring(url.lastIndexOf('/')+1)
                     docUrl.setAttribute('download', filename);
                     document.body.appendChild(docUrl);
                     docUrl.click();
                });
          }
    }
};
</script>
