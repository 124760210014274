<template>
    <MyModal :on_off="modal">ma'lumotlar yuklanmoqda...</MyModal>
  <div class="courses">
    <main>
      <section class="py-0 text-center container">
        <div class="row py-lg-5">
          <div class="col-lg-6 col-md-8 mx-auto">
            <h1 class="fw-light">Kurslar</h1>
          </div>
        </div>
      </section>

      <div class="album py-5 bg-light">
        <div class="container">

          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
            <div class="col" v-for="card in cards" :key='card'>
              <div class="card shadow-sm" style="min-height:25rem;">
                <img title="Taqdimot" class="bd-placeholder-img mx-auto card-img-top" style="width:196px; " :src="card.get_image">

                <div class="card-body">
                  <p class="card-text text-center">{{truncate_text(card.title,139,'...')}}</p>
                  
                </div>
                <div class="card-footer">
                    <div class="btn-group">
                      <a :href="'/news/' + card.slug" class=" mb-3  btn btn-sm btn-outline-secondary"
                        >Batafsil</a
                      >
                    </div>
                  </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>

    </main>
  </div>
</template>
<style lang="scss">

</style>
<script>
import axios from "axios";
import MyModal from "@/components/MyModal.vue";
export default {
    components:{
        MyModal,
    },
  data(){
    return{
      cards:[],
      modal:true
    }
  },
  mounted() {
    this.modal=true
        setTimeout(()=>{          
          axios.get(`courses/get_all_blogs/`).then((response) => {
            this.cards = response.data;
            this.modal=false
          });
        },500)
  },
  methods:{
    truncate_text(text,length,suffix){
      if (text.length > length) {
        return text.substring(0, length) + suffix;
    } else {
        return text;
    }
      // return new String(text).slice(0,length)
    }
  },
 
};
</script>