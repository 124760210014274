<template>
    <div class="col-md-4 d-none d-lg-block">
        <div class="position-sticky" style="top: 2rem">
        <div class="p-4 mb-3 bg-light rounded">
            <label class="fs-5">Yangiliklar</label>
            <h4 class="fst-italic mt-3">Maqola mavzu</h4>
            <p class="mb-0">Customize this section to tell your visitors a little bit about your publication, writers, content, or something else entirely. Totally up to you.</p>
            <h4 class="fst-italic mt-3">Maqola mavzu</h4>
            <p class="mb-0">Customize this section to tell your visitors a little bit about your publication, writers, content, or something else entirely. Totally up to you.</p>
        </div>
        </div>
    </div>
</template>